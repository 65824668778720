import React from "react";
import { Image } from "../components/Image/Image";

const ProfitSlice = ({ slice }) => {

    const imgSrc = slice.primary.profits_img

    return (
        <div className="profitSliceContainer">
            <Image 
                src={imgSrc}
                loading={"eager"}
                fadeIn={false}
                className={"profitSliceContainer__img"}
            />
        </div>
    )
}

export default ProfitSlice