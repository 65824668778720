import React from "react";
import { Image } from "../Image/Image";

const SignalItem = ({ item, title }) => {

    return (

        <div className="signalsItem" id={`${title} ${item.filter_title}`}>
            <Image 
                src={item.img}
                loading={"eager"}
                fadeIn={false}
                className={"signalsItem__img"}
            />
            <p className="signalsItem__description">{item.description}</p>
        </div>
    )
}


export default SignalItem