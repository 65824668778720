import React from "react";
import SectionLayout from "../../layouts/SectionLayout"
import BtnContainer from "./BtnContainer";

const BlogBanner = ({ title, btn1title, btn2title }) => {

    return (

        <SectionLayout>
            <div className="blogContainer">
                  <div className="col-lg-10">
                    <div className="cta-banner">
                      <h2 className="cta-banner__title">
                        {title}
                      </h2>
                      <BtnContainer title1={btn1title} title2={btn2title}/>
                    </div>
                  </div>
                </div>
        </SectionLayout>
    )
}

export default BlogBanner