import React from "react";
import SideNav from "../components/SideNavComponent/SideNavComponent";
import { Signalslices } from "../components/SlizeZone/SignalSlices";

const SignalsContent = ({ signalsData }) => {

    return (

        <div className="d-flex">
            <SideNav data={signalsData} />
            <div className="slices">
                <Signalslices slices={signalsData} />
            </div>
        </div>
    )
}

export default SignalsContent