import React from "react";
import BlogContent from "../BlogContent/BlogContent";
import BlogImage from "../BlogImage/BlogImage";
import BlogVideo from "../BlogVideo/BlogVideo";
import BlogTable from "../BlogTable/BlogTable";

export const BlogSlices = ({ slices }) => {

    const sliceComponents = {
        blog_rich_text: BlogContent,
        blog_image: BlogImage,
        blog_video: BlogVideo,
        blog_table: BlogTable,
    }

    return slices.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]

        if(SliceComponent) {
            return <SliceComponent slice={slice} key={`slice ${index}`} index={index} />
        }
        return null
    })
}