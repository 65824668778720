import React from "react";
import { Image } from "../Image/Image";

const BlogHero = ({ kicker, title, image}) => {

    return (

        <section className="blogHero">
            <Image src={image} className="bgHero" loading={"eager"} fadeIn={false}/>
            <div className="blogHero__cta">
                <p className="blogHero__cta-kicker">{kicker}</p>
                <h1 className="blogHero__cta-title">{title}</h1>
            </div>
        </section>
    )
}

export default BlogHero