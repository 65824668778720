import React from "react";
import { Signalslices } from "../components/SlizeZone/SignalSlices";
import SignalsContent from "./SignalsContent";

const SignalsSection = ({ profitSlice, signalsData }) => {


    return (

        <>
            {signalsData.length !== 0 &&
                <div className="layout-container">
                    <Signalslices slices={profitSlice}/>
                    <SignalsContent signalsData={signalsData} />
                </div>
            }
        </>
    )
}


export default SignalsSection