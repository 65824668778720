import React from "react";
import { BlogSlices } from "../components/SlizeZone/BlogSlices";
import SectionLayout from "../layouts/SectionLayout";


const BlogPostSecton = ({ slices }) => {


    return (

        <SectionLayout>
            <div className="blogContainer">
                <div className="col-lg-8 contentContainer">
                    <BlogSlices slices={slices} />
                </div>
            </div>
        </SectionLayout>
    )
}


export default BlogPostSecton