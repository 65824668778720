import React from "react";
import VideoComponent from "../VideoComponent/VideoComponent"

const BlogVideo = ({ slice }) => {

    const movieId = slice.primary.id
    const movieTitle = slice.priary.moviet_itle

    return (

        <>
            <VideoComponent 
                videoID={movieId}
                videoTitle={movieTitle}
            />
        </>
    )
}

export default BlogVideo