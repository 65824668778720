import React from "react";
import BlogBanner from "../components/BlogBanner/BlogBanner";
import BlogSection from "./BlogSection";
import SignalsSection from "./SignalsSection";

const BlogContentSection = ({ data }) => {
    
    const {
        title, 
        kicker, 
        image, 
        slices, 
        bannerTitle, 
        btn1title, 
        btn2title
    } = data

    const profitSlice = slices.filter(item => (
        item.slice_type === 'profit_slice'
    ))

    const signalsData = slices.filter(item => (
        item.slice_type === 'exchange_signals'
    ))

    const contentSlices = slices.filter(item => (
        item.slice_type !== 'exchange_signals' && 
        item.slice_type !== 'profit_slice'
    ))

    return (

        <>
            <BlogSection 
                title={title}
                kicker={kicker} 
                image={image} 
                contentSlices={contentSlices}
            />
            <SignalsSection 
                profitSlice={profitSlice} 
                signalsData={signalsData} 
            />
            <BlogBanner 
                title={bannerTitle} 
                btn1title={btn1title}
                btn2title={btn2title} 
            />
        </>
    )
}


export default BlogContentSection