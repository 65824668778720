import React from "react";
import NavItem from "./NavItem"


const SideNav = ({ data }) => {


    return (
        <aside className="sideNavbar"> 
            <h4>Navigation</h4>
            {data.map(item => (
                <NavItem item={item}/>
            ))}
        </aside>

    )
}



export default SideNav