import React from "react";
import BlogHero from "../components/BlogHero/BlogHero"
import BlogPostSecton from "./BlogPostSection";

const BlogSection = ({ title, kicker, image, contentSlices }) => {

    return (

        <>
            <BlogHero title={title} kicker={kicker} image={image} />
            <BlogPostSecton slices={contentSlices} />
        </>
    )
}


export default BlogSection