import React from "react";
import htmlSerializer  from "../../utils/htmlSerializer"
import { RichText } from "prismic-reactjs"

const BlogContent = ({ slice }) => {

    const content = slice.primary.blog_content.raw

    return (
        <>
            <RichText render={content} serializeHyperlink={htmlSerializer} />
        </>
    )
}

export default BlogContent