import React from "react";

const BlogTable = ({ slice }) => {

    const table = slice.primary.table.text

    return (
        <div dangerouslySetInnerHTML={{ __html: table }} className="preformatted" />
    )
}

export default BlogTable