import React from "react";

const BtnContainer = ({ title1, title2 }) =>{

    return (

        <div className="btn-container">
            <a href={'https://app.cryptoquickscanner.com/sign_in'} className="btn blogButton btn-primary">
                {title1}
            </a>
            <a className="btn blogButton btn-outline-secondary" href={'https://app.cryptoquickscanner.com/sign_up'}>{title2}</a>
        </div>
    )
}


export default BtnContainer