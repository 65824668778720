import React from "react";
import ProfitSlice from "../../slices/ProfitSlice";
import SignalsSlice from "../../slices/SignalsSlice";

export const Signalslices = ({ slices }) => {

    const sliceComponents = {
        profit_slice: ProfitSlice,
        exchange_signals: SignalsSlice,
    }


    return slices.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]

        if(SliceComponent) {
            return <SliceComponent slice={slice} key={`slice ${index}`} index={index} />
        }
        return null
    })
}